<template>
  <div id="popup" v-if="display">
    <div class="content">
      <h3 v-html="$t('actionEndPopup.headline')"></h3>

      <div v-html="$t('actionEndPopup.text')"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let currentDate = new Date();
    if (currentDate >= this.campaignEnd) {
      this.display = true;
    }
  },
  data() {
    return {
      display: false,
      campaignEnd: new Date("2024-11-05"),
    };
  },
  methods: {},
};
</script>

<style></style>
